import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import config from './config';

if (
  process.env.REACT_APP_STAGE === 'production' &&
  window.location.href.indexOf('localhost') === -1 &&
  window.location.href.indexOf('staging') === -1
) {
  setupLogRocketReact(LogRocket);
  LogRocket.init(config.LOGROCKET_ID, {
    release: process.env.REACT_APP_BUILD_ID,
    network: {
      // dont track calls to stripe
      requestSanitizer: (request: any) => {
        if (request.url.toLowerCase().indexOf('stripe.com') !== -1) {
          return null;
        }

        return request;
      },
    },
  });
}
